import React from "react"
import {
  Flex, Heading, Text, Box,
} from "@chakra-ui/react"
import StatRow from "components/StatRow"
import Link from "components/elements/Link"
import { asNumber } from "utilities/strings"
import { TextMessageDeliveryCounts } from "sharedTypes"

type Props = {
  deliveryCounts: TextMessageDeliveryCounts
}

const TextMessageEngagements = ({
  deliveryCounts: {
    sent,
    delivered,
    deliveryUnknown,
    undelivered,
    failed,
  },
}: Props) => {
  const allRecipients = sent + delivered + deliveryUnknown + undelivered + failed

  return (
    <Box>
      <Box borderBottomWidth={1} pb={4} mb={3}><Text fontSize="2xl">Text Delivery</Text></Box>
      <Box ml={5}>
        <Flex justify="space-between" mb={6}>
          <Heading fontSize="lg" fontWeight="normal">All Recipients</Heading>
          <Text fontSize="lg">{asNumber(allRecipients)}</Text>
        </Flex>
        <Flex flexDirection="column" gap={0.5}>
          <StatRow label="Sent" count={sent} total={allRecipients} color="textStatus.sent" />
          <StatRow label="Delivered" count={delivered} total={allRecipients} color="textStatus.delivered" />
          <StatRow label="Delivery Unknown" count={deliveryUnknown} total={allRecipients} color="textStatus.deliveryUnknown" />
          <StatRow label="Undelivered" count={undelivered} total={allRecipients} color="textStatus.undelivered" />
          <StatRow label="Failed" count={failed} total={allRecipients} color="textStatus.failed" />
        </Flex>
        <Text mt={5}>
          <>To learn more, see: </>
          <Link target="_blank" href="#">SMS deliverability and error messages</Link>
        </Text>
      </Box>
    </Box>
  )
}

export default TextMessageEngagements
