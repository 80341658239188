import React from "react"
import Modal from "components/dialogs/Modal"
import { useHistory } from "react-router-dom"
import { eventGuestPath } from "utilities/routes"
import Avatar from "components/elements/Avatar"
import {
  Box, Divider, Flex, Heading,
} from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { DioboxEvent, Guest } from "sharedTypes"
import Header from "../Header"
import Scorecard from "./Scorecard"
import ActivityList from "../ActivityList"

interface Props {
  guest: Guest
  event: DioboxEvent
}

const HistoryAndScoreboard = ({ guest, event }: Props) => {
  const history = useHistory()
  const {
    data: {
      currency,
      title,
      website,
    },
  } = useCurrentEvent()

  const {
    eventId,
    id: guestId,
  } = guest

  const handleClose = () => {
    history.push(eventGuestPath(eventId, guestId))
  }

  return (
    <Modal
      show
      hide={handleClose}
      title="Guest History & Scorecard"
      noPadding
    >
      <Box p={6}>
        <Header guest={guest} event={event} />
        <Scorecard guest={guest} currency={currency} />
      </Box>
      <Divider mt={6} />
      <Flex align="center" p={8}>
        <Avatar borderRadius="base" size="lg" src={website?.coverImageUrl} name={title} />
        <Heading ml={8} fontSize="2xl">{title}</Heading>
      </Flex>
      <ActivityList guest={guest} event={event} />
    </Modal>
  )
}

export default HistoryAndScoreboard
