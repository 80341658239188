import React, { useEffect, useState } from "react"
import { TextMessage, TextMessageReport } from "sharedTypes"
import {
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Box,
  Flex,
  Text,
  Avatar,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import { eventTextMessagePath } from "utilities/routes"
import { useHistory, useParams } from "react-router-dom"
import FullScreenModalNav from "components/dialogs/FullScreenModalNav"
import Select from "components/ReactHookForm/Select"
import Button from "components/Buttons/Button"
import Modal from "components/dialogs/Modal"
import Paginate from "components/elements/Paginate"
import { asDate } from "utilities/strings"
import Icon from "components/elements/Icon"
import { useQuery } from "react-query"
import { useForm } from "react-hook-form"
import { getTextMessageReport } from "api/texts"
import _ from "lodash"
import Spinner from "components/elements/Spinner"

type Props = {
  textMessage: TextMessage
}

const TextReport = ({
  textMessage: {
    name,
    updatedAt,
    externalId,
  },
}: Props) => {
  const history = useHistory()
  const { eventId, textMessageId } = useParams<{ eventId: string, textMessageId: string }>()
  const [filter, setFilter] = useState("")
  const [page, setPage] = useState(1)
  const FILTER_OPTIONS = [
    { label: "All Text Messages", value: "" },
    { label: "Sent", value: "sent" },
    { label: "Delivered", value: "delivered" },
    { label: "Delivery Unknown", value: "delivery_unknown" },
    { label: "Undelivered", value: "undelivered" },
    { label: "Failed", value: "failed" },
  ]

  const close = () => {
    history.push(eventTextMessagePath(eventId!, externalId))
  }

  const onSubmit = (values) => {
    setFilter(values.filter)
  }

  const {
    data: report,
    isLoading,
  } = useQuery(
    ["textMessageReport", textMessageId, filter, page],
    () => getTextMessageReport(eventId!, textMessageId, page, filter).then(({ data }) => data),
  )

  const { control, handleSubmit, watch } = useForm({
    defaultValues: { filter },
  })

  const filterValue = watch("filter")

  useEffect(() => {
    handleSubmit(onSubmit)()
  }, [filterValue])

  const handleChangePage = (selectedPage: number) => {
    setPage(selectedPage)
  }

  const totalPages = Math.ceil((report?.length ?? 0) / 50)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show
        noPadding
        size="full"
        hide={close}
        header={(
          <FullScreenModalNav type="header">
            <Text>Text Report</Text>
            <Text>{name}: {asDate(updatedAt)}</Text>
            <Link onClick={close} color="blue.300" fontWeight="bold">
              Close
              <Icon icon="cancel-circle" ml={2} />
            </Link>
          </FullScreenModalNav>
          )}
      >
        <Box overflowY="scroll" p={5} my={12}>
          <Flex justify="space-between">
            <Box>
              <Select
                name="filter"
                control={control}
                options={FILTER_OPTIONS}
              />
            </Box>
            <Flex align="flex-start">
              {totalPages > 1
                && (
                  <Box mr={5}>
                    <Paginate
                      pageCount={totalPages}
                      currentPage={page}
                      onChangePage={handleChangePage}
                    />
                  </Box>
                )}
              <Button
                variant="outline"
                size="sm"
                onClick={() => {}}
              >Export to CSV
              </Button>
            </Flex>
          </Flex>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Phone Number</Th>
                <Th>Sent</Th>
                <Th>Delivery Status</Th>
              </Tr>
            </Thead>
            {isLoading ? <Tr><Td colSpan={4}><Spinner /></Td></Tr> : (
              <Tbody>
                { (report || []).map((reportItem) => (
                  <Row reportItem={reportItem} />
                ))}
              </Tbody>
            )}
          </Table>
        </Box>
      </Modal>
    </form>
  )
}

type RowProps = {
  reportItem : TextMessageReport
}

const Row = ({
  reportItem: {
    guest: { name: guestName },
    data: { to, status, sid },
    createdAt,
  },
}: RowProps) => (
  <Tr key={sid}>
    <Td><Flex alignItems="center" gap={2}><Avatar bg={`textStatus.${status}`} name={guestName} />{guestName}</Flex></Td>
    <Td>{to}</Td>
    <Td>{asDate(createdAt)}</Td>
    <Td>{_.capitalize(status) }</Td>
  </Tr>
)

export default TextReport
