import React, { ReactNode, useEffect } from "react"
import { Box, ButtonProps, Flex } from "@chakra-ui/react"
import SubmitButton from "components/Buttons/SubmitButton"
import { useForm } from "react-hook-form"
import { errorToast, successToast } from "utilities/toasts"
import { Prompt } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "../Buttons/Button"
import Confirm from "../dialogs/Confirm/Confirm"
import ConfirmDelete, { Props as ConfirmDeleteProps } from "../dialogs/Confirm/ConfirmDelete"
import DangerButton from "../Buttons/DangerButton"

type Props = {
  values: any
  onSubmit: (values?: any) => (Promise<any>|void)
  children: ReactNode | ((formProps) => ReactNode)
  successMessage?: string
  name?: string
  submitButtonColorScheme?: ButtonProps["colorScheme"]
  submitButtonText?: string
  confirmationTitle?: string
  confirmationText?: ReactNode | string
  confirmButtonLabel?: string
  confirmDeleteProps?: Omit<ConfirmDeleteProps, "children">
  noPadding?: boolean
  showCancel?: boolean
  disableSubmitWhenNotDirty?: boolean
  onChange?: (values) => void
  validationSchema?: any
  submitIsDisabled?: boolean
}

const ContainedForm = ({
  values,
  name = "",
  successMessage,
  onSubmit,
  submitButtonColorScheme,
  submitButtonText = "Save",
  confirmationTitle,
  confirmationText,
  confirmButtonLabel,
  confirmDeleteProps,
  children,
  noPadding = false,
  showCancel = true,
  disableSubmitWhenNotDirty = true,
  onChange,
  validationSchema,
  submitIsDisabled,
}: Props) => {
  const form = useForm({
    values,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode: "onChange",
  })

  const {
    formState,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    reset,
    watch,
  } = form

  useEffect(() => {
    const subscription = watch((data) => onChange?.(data))

    return () => subscription.unsubscribe()
  }, [watch])

  let resolvedChildren = children
  if (typeof children === "function") {
    resolvedChildren = children(form)
  }

  const paddingProps = noPadding ? {} : { px: 6, py: 4 }

  const handlePageLeave = (event) => {
    if (showCancel && isDirty) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (showCancel) {
      window.addEventListener("beforeunload", handlePageLeave)
    }

    return () => {
      if (showCancel) {
        window.removeEventListener("beforeunload", handlePageLeave)
      }
    }
  }, [isDirty])

  const submitHandler = (props) => onSubmit(props)?.then(() => {
    if (successMessage || name) {
      const title = successMessage ?? `${name} Saved`
      successToast({ title })
    }
    reset({}, { keepValues: true })
  }).catch((error) => {
    errorToast({ title: error.message })
  })

  const cancelHandler = () => reset(values)

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Prompt
        when={formState.isDirty}
        message="Are you sure you want to leave this page? You have some unsaved changes."
      />
      <Box borderRadius="xl" borderWidth={1} bgColor="white">
        <Box {...paddingProps}>
          {resolvedChildren as ReactNode}
        </Box>
        <Flex borderTopWidth={1} px={6} py={4} justify="flex-end">
          {showCancel && (
          <Box marginRight={4}>
            <Button
              colorScheme="button.light"
              onClick={cancelHandler}
              isDisabled={!isDirty || isSubmitting}
            >Cancel
            </Button>
          </Box>
          )}
          <FormButton
            confirmationText={confirmationText}
            confirmationTitle={confirmationTitle}
            confirmButtonLabel={confirmButtonLabel}
            submitButtonColorScheme={submitButtonColorScheme}
            onClick={handleSubmit(submitHandler)}
            isSubmitting={isSubmitting}
            submitButtonText={submitButtonText}
            confirmDeleteProps={confirmDeleteProps}
            disabled={submitIsDisabled
              || (disableSubmitWhenNotDirty ? !isDirty : false || !isValid)}
          />
        </Flex>
      </Box>
    </form>
  )
}

const FormButton = ({
  confirmationText,
  confirmationTitle,
  confirmButtonLabel,
  submitButtonColorScheme,
  onClick,
  isSubmitting,
  submitButtonText,
  confirmDeleteProps,
  disabled,
}) => {
  if (confirmationText) {
    return (
      <Confirm
        confirmationTitle={confirmationTitle}
        confirmationText={confirmationText}
        confirmButtonLabel={confirmButtonLabel}
        stopChildClickPropagation
      >
        <Button
          onClick={onClick}
          colorScheme={submitButtonColorScheme}
          isLoading={isSubmitting}
          isDisabled={disabled}
        >
          {submitButtonText}
        </Button>
      </Confirm>
    )
  }

  if (confirmDeleteProps !== undefined) {
    return (
      <ConfirmDelete {...confirmDeleteProps}>
        <DangerButton onClick={onClick} isLoading={isSubmitting} />
      </ConfirmDelete>
    )
  }

  return (
    <SubmitButton
      colorScheme={submitButtonColorScheme}
      submitting={isSubmitting}
      text={submitButtonText}
      disabled={disabled}
    />
  )
}

export default ContainedForm
