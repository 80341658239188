import {
  del, get, post, put,
} from "services/api"
import { currentAccountId } from "services/authentication"
import {
  PaginationMeta, TextMessage, TextMessageReport, TextMessageTest,
} from "sharedTypes"

const textsBaseURL = (eventId: string) => `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/texts`

export const getTextMessages = (eventId: string, page: number, perPage?: number) => get<{data: TextMessage[], meta: PaginationMeta}>(`${textsBaseURL(eventId)}.json?page=${page}${perPage ? `&per=${perPage}` : ""}`)

export const getTextMessage = (eventId: string, textMessageId: string) => get<TextMessage>(`${textsBaseURL(eventId)}/${textMessageId}.json`)

export const putTextMessage = (eventId: string, textMessageId: string, textMessage: Partial<TextMessage>) => put<TextMessage>(`${textsBaseURL(eventId)}/${textMessageId}.json`, textMessage)

export const postTextMessage = (eventId: string, textMessage: TextMessage) => post<TextMessage>(`${textsBaseURL(eventId)}.json`, textMessage)

export const deleteTextMessage = (eventId: string, textMessageId: string) => del(`${textsBaseURL(eventId)}/${textMessageId}.json`)

export const sendTextMessage = (eventId: string, textMessageId: string) => post<TextMessage>(`${textsBaseURL(eventId)}/${textMessageId}/send_all.json`)

export const sendTextMessageTest = (eventId: string, textMessageTest: TextMessageTest) => post<TextMessage>(`${textsBaseURL(eventId)}/send_test.json`, { text: textMessageTest })

export const getTextMessageReport = (
  eventId: string, textMessageId: string, page: number, status: string,
) => {
  const statusParam = status === "" ? "" : `&status=${status}`

  return get<TextMessageReport[]>(`${textsBaseURL(eventId)}/${textMessageId}/report.json?page=${page}&per_page=50${statusParam}`)
}

export default null
